import React from 'react'
import styled, {keyframes} from 'styled-components'

import {Spinner as SpinnerIcon} from '@pleo-io/telescope-icons'

const loaderKeyframes = keyframes`
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
`

const SpinnerWrapper = styled.span`
    display: inline-flex;
    align-items: center;
    justify-content: center;
    color: currentColor;

    svg {
        animation: ${loaderKeyframes} 1.2s linear infinite;

        @media (prefers-reduced-motion: reduce) {
            animation: none;
        }
    }
`

export interface spinnerProps {
    size?: 16 | 24
}

export const Spinner = ({size = 16}: spinnerProps) => (
    <SpinnerWrapper data-telescope="spinner">
        <SpinnerIcon size={size} />
    </SpinnerWrapper>
)

Spinner.displayName = 'Spinner'
